<template>
    <v-card>
        <v-badge v-if="herramienta.count_unidades"
                 color="green"
                 :content="herramienta.count_unidades"
                 overlap
                 offset-x="-10"
                 offset-y="-10"
        >
        </v-badge>
        <img :src="largeImage" :alt="herramienta.tipo_herramienta_nombre" class="w100" />
<!--        <v-img :src="herramienta.imagen" aspect-ratio="1">

        </v-img>-->

        <v-card-text>
            <p class="mb-0"><b>{{ herramienta.tipo_herramienta_nombre }}</b></p>
            <p class="mb-0" v-if="herramienta.modelo">{{ herramienta.modelo }}</p>
            <p class="mb-0" v-if="herramienta.modelo">{{ herramienta.modelo }}</p>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
  name: "Herramienta",
  props: {
    herramienta: {
      type: Object,
      required: false,
    }
  },
  computed: {
    largeImage() {
      return this.herramienta.archivo[0].thumbnails.large.url;
    }
  }
}
</script>

<style scoped>

</style>
