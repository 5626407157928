<template>
    <v-container fluid>
        <v-row>
            <v-col cols="1">
                <v-img :src="tipoEnsamble.imagen" aspect-ratio="1"/>
            </v-col>
            <v-col cols="11">
                <h1>{{ (tipoEnsamble && tipoEnsamble.nombre) ? tipoEnsamble.nombre : $route.params.nomenclatura }}</h1>
                <hr/>
            </v-col>
        </v-row>

        <v-row v-if="loaded">
            <v-col cols="12">
                <v-container fluid class="px-0">
                    <v-row v-for="espacio in espaciosPorTipoEnsamble(tipoEnsamble.id)" :key="espacio.id">
                        <v-col cols="12">
                            <router-link tag="h3" class="text-decoration-underline pointer" :to="{ path: `/espacios/${espacio.nomenclatura}` }">
                                {{ espacio.nombre }}
                            </router-link>
                            <hr />
                        </v-col>

                        <v-container fluid v-if="loaded">
                            <v-row>
                                <v-col class="fill-height" cols="12" lg="12" v-for="ensamble in ensamblesPorTipoYEspacio(tipoEnsamble.id, espacio.id)" :key="ensamble.id">
                                    <v-card>
                                        <v-card-title>
                                            {{ ensamble.referencia }}
                                        </v-card-title>
                                        <v-card-text>
                                            <MiniHerramienta
                                                :id-herramienta="idHerramienta"
                                                v-for="(idHerramienta, key) in ordereddd(ensamble.herramientas)"
                                                :key="Math.random() + key" />
<!--                                            <img
                                                :src="imagen.url"
                                                v-for="(imagen, key) in ordereddd(ensamble.unidades_herramientas_imagenes)"
                                                :key="Math.random() + key" :alt="imagen.url" class="inline-images" />-->
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import { orderBy } from 'lodash'
import MiniHerramienta from "@/components/MiniHerramienta";

export default {
  name: "TipoEnsamble",
  components: {
    MiniHerramienta
  },
  data() {
    return {
      tipoEnsamble: {},
      loaded: false
    }
  },
  computed: {
    ...mapGetters(['tipoEnsamblePorNomenclatura', 'espaciosPorTipoEnsamble', 'ensamblesPorTipoYEspacio']),
  },
  methods: {
    ...mapActions(['obtenerEnsambles', 'obtenerHerramientas']),
    ordereddd(items) {
      return orderBy(items, 'id')
    }
  },
  mounted() {
    this.obtenerEnsambles().then(() => {
      this.obtenerHerramientas().then(() => {
        this.tipoEnsamble = this.tipoEnsamblePorNomenclatura(this.$route.params.nomenclatura)
        this.loaded = true;
      });
    });
  }
}
</script>
