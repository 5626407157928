<template>
    <v-container fluid>
        <v-row>
            <v-col cols="1">
                <v-img :src="tipoFamilia.imagen" aspect-ratio="1" />
            </v-col>
            <v-col cols="11">
                <h1>{{ (tipoFamilia && tipoFamilia.nombre) ? tipoFamilia.nombre : $route.params.nomenclatura }}</h1>
                <hr/>
            </v-col>
        </v-row>

        <v-row v-if="tipoFamilia && tipoFamilia.nombre">
            <v-col cols="12">
                <v-container fluid class="px-0">
                    <v-row v-for="marca in marcasPorTipoFamilia(tipoFamilia)" :key="marca.id">
                        <v-col cols="12">
                            <router-link tag="h3" class="text-decoration-underline pointer" :to="{ path: `/marcas/${marca.nomenclatura}` }">
                                {{ marca.nombre }}
                            </router-link>
                            <hr />
                        </v-col>

                        <v-container fluid>
                            <v-row>
                                <v-col class="fill-height" cols="6" lg="2" v-for="herramienta in herramientasPorMarcaYFamilia(marca.id, tipoFamilia.id)" :key="herramienta.id">
                                    <Herramienta :herramienta="herramienta" />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Herramienta from "@/components/Herramienta";

export default {
  name: "Marca",
  components: { Herramienta },
  data() {
    return {
      loaded: false,
      tipoFamilia: []
    }
  },
  computed: {
    ...mapGetters(['familiaPorNomenclatura', 'marcasPorTipoFamilia', 'herramientasPorMarcaYFamilia'])
  },
  methods: {
    ...mapActions(['obtenerMarcas', 'obtenerFamilias', 'obtenerHerramientas'])
  },
  mounted() {
    this.obtenerHerramientas().then(() => {
      this.tipoFamilia = this.familiaPorNomenclatura(this.$route.params.nomenclatura)
      this.loaded = true;
      console.log("loaded");
    });
  }
}
</script>

<style scoped>

</style>
