import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false

let mixin = {
  methods: {
    formatPrice(value) {
      let val = (value/1).toFixed(2).replace(',', '.')
      return "$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
}

Vue.mixin(mixin);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
