<template>
    <v-container fluid v-if="loaded">
        <v-row>
            <v-col>
                <h1>Ensambles</h1>
                <hr />
            </v-col>
        </v-row>
        <v-row v-for="tipoEnsamble in tiposEnsamblesOrdenados" :key="tipoEnsamble.id">
            <v-col cols="12">
                <h2>{{ tipoEnsamble.nombre }}</h2>
                <hr />
            </v-col>
            <v-col lg="12" v-for="ensamble in ensamblesPorTipoDeEnsamble(tipoEnsamble.id)" :key="ensamble.id">
                <v-card class="fill-height">
                    <v-card-title>
                        {{ ensamble.nombre }}

                        <v-spacer></v-spacer>

                        <v-btn icon @click="ensamble.show = !ensamble.show">
                            <v-icon>{{ ensamble.show ? 'mdi-arrow-up' : 'mdi-arrow-down'}}</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text v-if="ensamble.show">
                        <p class="mb-0">
                            Tipo de Ensamble: {{ ensamble.tipo_ensamble_nombre }}
                        </p>
                        <p class="mb-0">
                            Notas: {{ ensamble.review_notas }}
                        </p>

                        <v-img
                            :src="imagen.url"
                            :aspect-ratio="1"
                            v-for="(imagen, key) in ensamble.unidades_herramientas_imagenes"
                            :key="Math.random() + key" :alt="imagen.url" class="inline-images"/>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: "Ensambles",
  data() {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapGetters(['tiposEnsamblesOrdenados', 'ensamblesPorTipoDeEnsamble']),
  },
  methods: {
    ...mapActions(['obtenerEnsambles', 'obtenerTiposEnsambles']),
  },
  mounted() {
    this.obtenerTiposEnsambles().then(() => {
      this.obtenerEnsambles().then(() => {
        this.loaded = true;
      });
    });
  }
}
</script>
