<template>
    <v-container fluid>
        <v-row>
            <v-col col="12">
                <h1>Espacios</h1>
                <hr/>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6" lg="2" v-for="espacio in espaciosOrdenados" :key="espacio.id">
                <v-card :to="{ path: `/espacios/${espacio.nomenclatura}` }">
                    <v-img :src="espacio.imagen" aspect-ratio="1" />
                    <v-card-text>
                        {{ espacio.nombre }}
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  name: "Espacios",
  computed: {
    ...mapGetters(['espaciosOrdenados'])
  },
  methods: {
    ...mapActions(['obtenerEspacios'])
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
