import Vue from 'vue';
import Vuex from 'vuex';
import Airtable from 'airtable';
import {orderBy} from 'lodash';

let base = null;

switch(window.location.pathname)
{
  case '/pop':
    base = new Airtable({apiKey: 'keyyYOEsQi6A1DFJ8'}).base('appgIPJP4LCM9cbFp');
    document.title = "POP - Inventario"
    break;

  case '/v3':
    base = new Airtable({apiKey: 'keyyYOEsQi6A1DFJ8'}).base('appAz1sE7PBYaQLYf');
    document.title = "V3 - Inventario"
    break;

  case '/vliveMedia':
    base = new Airtable({apiKey: 'keyyYOEsQi6A1DFJ8'}).base('appiL79EzOKmysAAc');
    document.title = "Vlive Media - Inventario"
    break;

  default:
    base = new Airtable({apiKey: 'keyyYOEsQi6A1DFJ8'}).base('appiL79EzOKmysAAc');
    document.title = "Vlive Media - Inventario"
    break;
}


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tipos_herramientas: [],
    herramientas: [],
    familias: [],
    marcas: [],
    ensambles: [],
    tipos_ensambles: [],
    espacios: [],
    loading: 0,
  },
  mutations: {
    /*
     * AGREGAR
     */
    AGREGAR_TIPOS_HERRAMIENTAS: (state, tipos_herramientas) => {
      state.tipos_herramientas.push(...tipos_herramientas)
    },
    AGREGAR_HERRAMIENTAS: (state, herramientas) => {
      state.herramientas.push(...herramientas)
    },
    AGREGAR_FAMILIAS: (state, familias) => {
      state.familias.push(...familias)
    },
    AGREGAR_MARCAS: (state, marcas) => {
      state.marcas.push(...marcas)
    },
    AGREGAR_ENSAMBLES: (state, ensambles) => {
      state.ensambles.push(...ensambles)
    },
    AGREGAR_TIPOS_ENSAMBLES: (state, tipos_ensambles) => {
      state.tipos_ensambles.push(...tipos_ensambles)
    },
    AGREGAR_ESPACIOS: (state, espacios) => {
      state.espacios.push(...espacios)
    },

    /*
     * LIMPIAR
     */
    LIMPIAR_TIPOS_HERRAMIENTAS: (state) => {
      state.tipos_herramientas = []
    },
    LIMPIAR_HERRAMIENTAS: (state) => {
      state.herramientas = []
    },
    LIMPIAR_FAMILIAS: (state) => {
      state.familias = []
    },
    LIMPIAR_MARCAS: (state) => {
      state.marcas = []
    },
    LIMPIAR_ENSAMBLES: (state) => {
      state.ensambles = []
    },
    LIMPIAR_TIPOS_ENSAMBLES: (state) => {
      state.tipos_ensambles = []
    },
    LIMPIAR_ESPACIOS: (state) => {
      state.espacios = []
    },
  },
  actions: {
    obtenerTiposHerramientas({rootState, commit}) {
      if (!rootState.herramientas.length) {
        commit('LIMPIAR_TIPOS_HERRAMIENTAS');
      }

      return new Promise(((resolve, reject) => {
        if (rootState.herramientas.length) {
          resolve()
        } else {
          rootState.loading++;
          base('tipos_herramientas').select().eachPage((records, fetchNextPage) => {
            let newRecords = [];

            records.forEach(record => {
              let insert = record.fields;
              insert.id = record.id;
              newRecords.push(insert);
            });

            commit("AGREGAR_TIPOS_HERRAMIENTAS", newRecords);
            fetchNextPage();
          }, (err) => {
            rootState.loading--;
            if (err) {
              console.error(err);
              reject();
            } else {
              resolve();
            }
          });
        }
      }));
    },
    obtenerHerramientas({rootState, commit}) {
      if (!rootState.herramientas.length) {
        commit('LIMPIAR_HERRAMIENTAS');
      }

      return new Promise(((resolve, reject) => {
        if (rootState.herramientas.length) {
          resolve()
        } else {
          rootState.loading++;
          base('herramientas').select().eachPage((records, fetchNextPage) => {
            let newRecords = [];

            records.forEach(record => {
              let insert = record.fields;
              insert.id = record.id;
              newRecords.push(insert);
            });

            commit("AGREGAR_HERRAMIENTAS", newRecords);
            fetchNextPage();
          }, (err) => {
            rootState.loading--;
            if (err) {
              console.error(err);
              reject();
            } else {
              resolve();
            }
          });
        }
      }));
    },
    obtenerFamilias({rootState, commit}) {
      if (!rootState.familias.length) {
        commit('LIMPIAR_FAMILIAS');
      }

      return new Promise(((resolve, reject) => {
        if (rootState.familias.length) {
          resolve();
        } else {
          rootState.loading++;
          base('familias_herramientas').select().eachPage((records, fetchNextPage) => {
            let newRecords = [];

            records.forEach(record => {
              let insert = record.fields;
              insert.id = record.id;
              newRecords.push(insert);
            });

            commit("AGREGAR_FAMILIAS", newRecords);
            fetchNextPage();
          }, (err) => {
            rootState.loading--;
            if (err) {
              console.error(err);
              reject();
            } else {
              resolve();
            }
          });
        }
      }));
    },
    obtenerMarcas({rootState, commit}) {
      if (!rootState.marcas.length) {
        commit('LIMPIAR_MARCAS');
      }

      return new Promise(((resolve, reject) => {
        if (rootState.marcas.length) {
          resolve()
        } else {
          rootState.loading++;
          base('marcas').select({
            fields: ['nombre', 'nomenclatura', 'imagen', 'count_herramientas', 'sum_herramientas_count_unidades', 'familias_herramientas'],
          }).eachPage((records, fetchNextPage) => {
            let newRecords = [];

            records.forEach(record => {
              let insert = record.fields;
              insert.id = record.id;
              insert.show = false;
              newRecords.push(insert);
            });

            commit("AGREGAR_MARCAS", newRecords);
            fetchNextPage();
          }, (err) => {
            rootState.loading--;
            if (err) {
              console.error(err);
              reject();
            } else {
              resolve();
            }
          });
        }
      }));
    },
    obtenerEnsambles({rootState, commit}) {
      if (!rootState.ensambles.length) {
        commit('LIMPIAR_ENSAMBLES');
      }

      return new Promise(((resolve, reject) => {
        if (rootState.ensambles.length) {
          resolve();
        } else {
          rootState.loading++;
          base('ensambles').select().eachPage((records, fetchNextPage) => {
            let newRecords = [];

            records.forEach(record => {
              let insert = record.fields;
              insert.id = record.id;
              insert.show = false;
              newRecords.push(insert);
            });

            commit("AGREGAR_ENSAMBLES", newRecords);
            fetchNextPage();
          }, (err) => {
            rootState.loading--;
            if (err) {
              console.error(err);
              reject();
            } else {
              resolve();
            }
          });
        }
      }));
    },
    obtenerTiposEnsambles({rootState, commit}) {
      if (!rootState.tipos_ensambles.length) {
        commit('LIMPIAR_TIPOS_ENSAMBLES');
      }

      return new Promise(((resolve, reject) => {
        if (rootState.tipos_ensambles.length) {
          resolve();
        } else {
          rootState.loading++;
          base('tipos_ensambles').select().eachPage((records, fetchNextPage) => {
            let newRecords = [];

            records.forEach(record => {
              let insert = record.fields;
              insert.id = record.id;
              newRecords.push(insert);
            });

            commit("AGREGAR_TIPOS_ENSAMBLES", newRecords);
            fetchNextPage();
          }, (err) => {
            rootState.loading--;
            if (err) {
              console.error(err);
              reject();
            } else {
              resolve();
            }
          });
        }
      }));
    },
    obtenerEspacios({rootState, commit}) {
      if (!rootState.espacios.length) {
        commit('LIMPIAR_ESPACIOS');
      }

      return new Promise(((resolve, reject) => {
        if (rootState.espacios.length) {
          resolve();
        } else {
          rootState.loading++;
          base('espacios').select().eachPage((records, fetchNextPage) => {
            let newRecords = [];

            records.forEach(record => {
              let insert = record.fields;
              insert.id = record.id;
              newRecords.push(insert);
            });

            commit("AGREGAR_ESPACIOS", newRecords);
            fetchNextPage();
          }, (err) => {
            rootState.loading--;
            if (err) {
              console.error(err);
              reject();
            } else {
              resolve();
            }
          });
        }
      }));
    },
  },
  getters: {

    /*
     *  OBTENER POR ID
     */
    herramientaPorID(state) {
      return(idHerramienta) => {
        return state.herramientas.find(herramienta => herramienta.id === idHerramienta);
      }
    },

    /*
     *  OBTENER POR NOMENCLATURA
     */
    marcaPorNomenclatura(state) {
      return(nomenclatura) => {
        return state.marcas.find(marca => marca.nomenclatura === nomenclatura)
      }
    },
    familiaPorNomenclatura(state) {
      return(nomenclatura) => {
        return state.familias.find(familia => familia.nomenclatura === nomenclatura)
      }
    },
    espacioPorNomenclatura(state) {
      return(nomenclatura) => {
        return state.espacios.find(espacio => espacio.nomenclatura === nomenclatura)
      }
    },
    tipoEnsamblePorNomenclatura(state) {
      return(nomenclatura) => {
        return state.tipos_ensambles.find(tipoEnsamble => tipoEnsamble.nomenclatura === nomenclatura)
      }
    },


    /*
     *  OBTENER ORDENADOS
     */
    tiposHerramientasOrdenadas(state) {
      return orderBy(state.tipos_herramientas, 'nombre')
    },
    marcasOrdenadas(state) {
      return orderBy(state.marcas, 'nombre')
    },
    familiasOrdenadas(state) {
      return orderBy(state.familias, 'nombre')
    },
    ensamblesOrdenados(state) {
      return orderBy(state.ensambles, 'tipo_ensamble_nombre')
    },
    tiposEnsamblesOrdenados(state) {
      return orderBy(state.tipos_ensambles, 'nombre')
    },
    espaciosOrdenados(state) {
      return orderBy(state.espacios, 'nombre')
    },

    /*
     *  OBTENER ORDENADOS Y FILTRADOS
     */
    herramientasPorTipoFamilia(state) {
      return (idTipoHerramienta) => {
        return orderBy(state.herramientas.filter(herramienta => herramienta.id_tipo_familia === idTipoHerramienta), 'nombre')
      }
    },
    ensamblesPorTipoDeEnsamble(state) {
      return (idTipoEnsamble) => {
        return orderBy(state.ensambles.filter(ensamble => ensamble.id_tipo_ensamble === idTipoEnsamble), 'nombre')
      }
    },
    marcasPorTipoFamilia(state) {
      return (familia) => {
        return orderBy(state.marcas.filter(marca => marca.familias_herramientas.includes(familia.id)), 'nombre')
      }
    },
    familiasPorMarca(state) {
      return(marca) => {
        return orderBy(state.familias.filter(familia => marca.familias_herramientas.includes(familia.id)), 'nombre');
      }
    },
    herramientasPorMarcaYFamilia(state) {
      return(idMarca, idTipoFamilia) => {
        return orderBy(state.herramientas.filter(herramienta => herramienta.id_tipo_familia === idTipoFamilia && herramienta.id_marca === idMarca), 'nombre')
      }
    },
    tiposEnsamblesPorEspacio(state) {
      return (idEspacio) => {
        return orderBy(state.tipos_ensambles.filter(tipoEnsamble => tipoEnsamble.ids_espacios.includes(idEspacio)), 'nombre')
      }
    },
    espaciosPorTipoEnsamble(state) {
      return (idTipoEnsamble) => {
        return orderBy(state.espacios.filter(espacio => espacio.ids_tipos_ensambles.includes(idTipoEnsamble)), 'nombre')
      }
    },
    ensamblesPorTipoYEspacio(state) {
      return(idTipoEnsamble, idEspacio) => {
        return orderBy(state.ensambles.filter(herramienta => herramienta.id_tipo_ensamble === idTipoEnsamble && herramienta.id_espacio === idEspacio), 'nombre')
      }
    },
  },
  modules: {}
})
