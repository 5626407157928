<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <h1>Tipos de Herramientas</h1>
                <hr />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6" lg="3" v-for="tipoHerramienta in tiposHerramientasOrdenadas" :key="tipoHerramienta.id">
                <v-card>
                    <v-card-text>
                        <p class="mb-0">{{ tipoHerramienta.nombre }}</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: "TiposHerramientas",
  computed: {
    ...mapGetters(['tiposHerramientasOrdenadas']),
  },
  methods: {
    ...mapActions(['obtenerTiposHerramientas'])
  },
  mounted() {
    this.obtenerTiposHerramientas();
  }
}
</script>

<style scoped>

</style>
