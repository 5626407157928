<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <h2>Marcas</h2>
                <hr />
            </v-col>

            <v-col cols="6" lg="2" v-for="marca in marcasOrdenadas" :key="marca.id">
                <v-card :to="{ path: `/marcas/${marca.nomenclatura}` }">
                    <v-img :src="marca.imagen" aspect-ratio="1" />
                    <v-card-text>
                        <p class="mb-0">{{ marca.nombre }}</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: "Marcas",
  computed: {
    ...mapGetters(['marcasOrdenadas']),
  },
  methods: {
    ...mapActions(['obtenerMarcas']),
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
