<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <h1>Tipos de Familias</h1>
                <hr/>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="fill-height" cols="12" lg="2" v-for="familia in familiasOrdenadas" :key="familia.id">
                <v-card :to="{ path: `/tiposFamilias/${familia.nomenclatura}` }">
                    <v-img :src="familia.imagen" aspect-ratio="1" />
                    <v-card-text>
                        <p class="mb-0">
                            {{ familia.nombre }}
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "TiposFamilias",
  computed: {
    ...mapGetters(['familiasOrdenadas']),
  },
  methods: {
    ...mapActions(['obtenerFamilias'])
  },
  mounted() {
    this.obtenerFamilias();
  }
}
</script>

<style scoped>

</style>
