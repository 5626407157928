<template>
    <v-container fluid>
        <v-row>
            <v-col cols="1">
                <v-img :src="espacio.imagen" aspect-ratio="1" v-if="espacio.imagen" />
            </v-col>
            <v-col cols="11">
                <h1>{{ (espacio && espacio.nombre) ? espacio.nombre : $route.params.nomenclatura }}</h1>
                <hr/>
            </v-col>
        </v-row>

        <v-row v-if="loaded">
            <v-col cols="12">
                <v-container fluid class="px-0">
                    <v-row v-for="tipoEnsamble in tiposEnsamblesPorEspacio(espacio.id)" :key="tipoEnsamble.id">
                        <v-col cols="12">
                            <router-link tag="h3" class="text-decoration-underline pointer" :to="{ path: `/tiposEnsambles/${tipoEnsamble.nomenclatura}` }">
                                {{ tipoEnsamble.nombre }}
                            </router-link>
                            <hr />
                        </v-col>

                        <v-container fluid>
                            <v-row>
                                <v-col class="fill-height" cols="12" lg="12" v-for="ensamble in ensamblesPorTipoYEspacio(tipoEnsamble.id, espacio.id)" :key="ensamble.id">
                                    <v-card>
                                        <v-card-title>
                                            {{ ensamble.referencia }}
                                        </v-card-title>
                                        <v-card-text>
                                            <v-img
                                                :src="imagen.url"
                                                :aspect-ratio="1"
                                                :data-nombre="imagen.filename"
                                                v-for="(imagen, key) in ordereddd(ensamble.unidades_herramientas_imagenes)"
                                                :key="Math.random() + key" :alt="imagen.url" class="inline-image"/>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {orderBy} from "lodash";
export default {
  name: "Espacio",
  data() {
    return {
      espacio: {},
      loaded: false
    }
  },
  computed: {
    ...mapGetters(['espacioPorNomenclatura', 'tiposEnsamblesPorEspacio', 'ensamblesPorTipoYEspacio'])
  },
  methods: {
    ...mapActions(['obtenerEspacios', 'obtenerTiposEnsambles', 'obtenerEnsambles']),
    ordereddd(items) {
      return orderBy(items, 'id')
    }
  },
  mounted() {
    this.obtenerEnsambles().then(() => {
      this.espacio = this.espacioPorNomenclatura(this.$route.params.nomenclatura)
      this.loaded = true;
      console.log("loaded");
    });
  }
}
</script>

<style scoped>
.inline-image {
    display: inline-flex !important;
    width: 135px !important;
    height: 150px !important;
}
</style>
