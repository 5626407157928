<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <h1>Tipos de Ensambles</h1>
                <hr/>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6" lg="2" v-for="tipoEnsamble in tiposEnsamblesOrdenados" :key="tipoEnsamble.id">
                <v-card :to="{ path: `/tiposEnsambles/${tipoEnsamble.nomenclatura}` }">
                    <v-img :src="tipoEnsamble.imagen" aspect-ratio="1" />
                    <v-card-text>
                        {{ tipoEnsamble.nombre }}
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  name: "TiposEnsambles",
  computed: {
    ...mapGetters(['tiposEnsamblesOrdenados'])
  },
  methods: {
    ...mapActions(['obtenerTiposEnsambles'])
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
