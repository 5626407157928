<template>
    <v-card class="d-inline-flex mr-4 mb-4" v-if="herramienta">
        <v-tooltip top color="black">
            <template v-slot:activator="{ on }">
                <v-card-text>
                    <div class="inline-image">
                        <div class="d-block">
                            <v-badge v-if="herramienta.count_unidades"
                                     color="green"
                                     :content="herramienta.count_unidades"
                                     overlap
                                     offset-x="0"
                                     offset-y="-10"
                            >
                            </v-badge>
                            <img
                                :src="largeImage"
                                :alt="herramienta.tipo_herramienta_nombre"
                                class="w100"
                                @onmouseenter="show = true"
                                v-on="on" />
                            <p class="text-center mb-0">
                                {{ herramienta.marca_nombre }}
                                <span v-if="herramienta.modelo">
                                    - {{ herramienta.modelo }}
                                </span>
                            </p>
                        </div>
                    </div>
                </v-card-text>
            </template>
            <div>
                <p class="mb-0">Marca: {{ herramienta.marca_nombre }}</p>
                <p class="mb-0">Modelo: {{ herramienta.modelo }}</p>
                <p class="mb-0">Precio: {{ formatPrice(herramienta.precio) }}</p>
                <p class="mb-0">Referencia: {{ herramienta.referencia }}</p>
            </div>
        </v-tooltip>
    </v-card>
</template>

<script>
import {mapState, mapGetters} from 'vuex';

export default {
  name: "MiniHerramienta",
  props: {
    idHerramienta: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      show: false,
      herramienta: {
        archivo: [
          {
            thumbnails: {
              large: {
                url: ''
              }
            }
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['herramientaPorID']),
    ...mapState(['herramientas']),
    largeImage() {
      return this.herramienta.archivo ? this.herramienta.archivo[0].thumbnails.large.url : '';
    }
  },
  mounted() {
    this.herramienta = this.herramientaPorID(this.idHerramienta);
  }
}
</script>

<style scoped>

</style>
