import Vue from 'vue'
import VueRouter from 'vue-router'
import Inicio from "@/views/Inicio";
import Ensambles from "@/views/Ensambles";
import Marcas from "@/views/Marcas";
import Marca from "@/views/Marca";
import TiposHerramientas from "@/views/TiposHerramientas";
import TiposFamilias from "@/views/TiposFamilias";
import TipoFamilia from "@/views/TipoFamilia";
import Espacios from "@/views/Espacios";
import Espacio from "@/views/Espacio";
import TiposEnsambles from "@/views/TiposEnsambles";
import TipoEnsamble from "@/views/TipoEnsamble";

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Inicio', component: Inicio },
  { path: '/ensambles', name: 'Ensambles', component: Ensambles },
  { path: '/marcas', name: 'Marcas', component: Marcas },
  { path: '/marcas/:nomenclatura', name: 'Marca', component: Marca },
  { path: '/tiposHerramientas', name: 'TiposHerramientas', component: TiposHerramientas },
  { path: '/tiposFamilias', name: 'TiposFamilias', component: TiposFamilias },
  { path: '/tiposFamilias/:nomenclatura', name: 'TipoFamilia', component: TipoFamilia },
  { path: '/espacios', name: 'Espacios', component: Espacios },
  { path: '/espacios/:nomenclatura', name: 'Espacio', component: Espacio },
  { path: '/tiposEnsambles', name: 'TiposEnsambles', component: TiposEnsambles },
  { path: '/tiposEnsambles/:nomenclatura', name: 'TipoEnsamble', component: TipoEnsamble },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
