<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h1>Inicio</h1>
                <hr />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6" lg="2">
                <v-card :to="{ path: `/espacios` }">
                    <v-card-text>
                        Espacios
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" lg="2">
                <v-card :to="{ path: `/tiposEnsambles` }">
                    <v-card-text>
                        Tipos de Ensambles
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" lg="2">
                <v-card :to="{ path: `/marcas` }">
                    <v-card-text>
                        Marcas
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" lg="2">
                <v-card :to="{ path: `/tiposFamilias` }">
                    <v-card-text>
                        Tipos de Familias
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
name: "Inicio"
}
</script>

<style scoped>

</style>
