<template>
    <v-app id="inspire" v-if="acceso">
        <v-app-bar app clipped-left>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-app-bar>

        <v-navigation-drawer
            v-model="drawer"
            app
            clipped
        >
            <v-list>
                <!-- Inicio -->
                <v-list-item link :to="{ path: '/' }">
                    <v-list-item-content>
                        <v-list-item-title>Inicio</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <!-- Espacios -->
                <v-list-group>
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title v-text="'Espacios'"></v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <v-list-item link :to="{ path: `/espacios/${espacio.nomenclatura}` }" v-for="espacio in espaciosOrdenados" :key="espacio.id">
                        <v-list-item-content>
                            <v-list-item-title class="pl-5">{{ espacio.nombre }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>

                <!-- Tipos Ensambles -->
                <v-list-group>
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title v-text="'Tipos de Ensambles'"></v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <v-list-item link :to="{ path: `/tiposEnsambles/${tipoEnsamble.nomenclatura}` }" v-for="tipoEnsamble in tiposEnsamblesOrdenados" :key="tipoEnsamble.id">
                        <v-list-item-content>
                            <v-list-item-title class="pl-5">{{ tipoEnsamble.nombre }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>

                <!-- Marcas -->
                <v-list-group>
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title v-text="'Marcas'"></v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <v-list-item link :to="{ path: `/marcas/${marca.nomenclatura}` }" v-for="marca in marcasOrdenadas" :key="marca.id">
                        <v-list-item-content>
                            <v-list-item-title class="pl-5">{{ marca.nombre }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>

                <!-- Tipos Familias -->
                <v-list-group>
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title v-text="'Tipos de Familias'"></v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item link :to="{ path: `/tiposFamilias/${familia.nomenclatura}` }" v-for="familia in familiasOrdenadas" :key="familia.id">
                        <v-list-item-content>
                            <v-list-item-title class="pl-5">{{ familia.nombre }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </v-navigation-drawer>

        <v-main>
            <router-view :key="$route.fullPath"></router-view>
        </v-main>
    </v-app>
    <v-app v-else>
        <v-container>
            <v-row v-show="!acceso" transition="fade-transition" class="justify-center">
                <v-col cols="12" md="6" lg="4" xl="3" class="ma-5 pa-5">
                    <h1>Inicio de Sesión</h1>
                    <v-text-field type="password" label="Contraseña" v-model="password"
                                  @keydown.enter="checarAcceso"></v-text-field>
                    <v-btn @click="checarAcceso" dark>Ingresar</v-btn>

                    <!--<p>Presentaciones</p>
                    <pre>
                        {{ presentacion }}
                    </pre>

                    <p>Usuario</p>
                    <pre>
                        {{ usuario }}
                    </pre>-->
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  data: () => ({
    title: '',
    drawer: true,
    cargado: false,
    acceso: false,
    password: '',
  }),
  computed: {
    ...mapGetters(['espaciosOrdenados', 'tiposEnsamblesOrdenados', 'marcasOrdenadas', 'familiasOrdenadas']),
  },
  methods: {
    ...mapActions(['obtenerEspacios', 'obtenerTiposEnsambles', 'obtenerMarcas', 'obtenerFamilias']),
    checarAcceso() {
      if(this.password === 'VL1V3') {
        switch(window.location.pathname)
        {
          case '/pop':
            this.title = "POP - Inventario"
            break;

          case '/v3':
            this.title = "V3 - Inventario"
            break;

          case '/vliveMedia':
            this.title = "Vlive Media - Inventario"
            break;

          default:
            this.title = "Vlive Media - Inventario"
            break;
        }


        this.acceso = true;
        this.obtenerEspacios();
        this.obtenerTiposEnsambles();
        this.obtenerMarcas();
        this.obtenerFamilias();
      }
    }
  },
  mounted() {
  }
}
</script>

<style>
.pointer {
    cursor: pointer;
}
.inline-image {
    display: inline-flex !important;
    width: 200px !important;
    height: 230px !important;
}
.w100 {
    width: 100% !important;
}
</style>
